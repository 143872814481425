import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import setBodyColor from '../setBodyColor'
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '../css/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import NavBar_MyMo from "./components/NavBar";

const withAuth0 = (WrappedComponent) => {
  return function Auth0Component(props) {
    const { user, logout, getAccessTokenSilently } = useAuth0();
    return (
      <WrappedComponent
        {...props}
        auth0={{ user, logout, getAccessTokenSilently }}
      />
    );
  };
};

let plot_ov_users = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

class MapOV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      lng: 8.251313,
			lat: 46.924143,
			zoom: 7,
			mapita: 9,
			tyle: 'mapbox://styles/mapbox/streets-v12',
      plot_parking_users: {},
      plot_ov_users: {}
    };
  }

  getMaps(token) {
        fetch('https://api.enterprise-mobility.ch/plot_ov_users', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }).then((res1) => res1.json())
          .then((json1) => {
            plot_ov_users = json1;
            this.state.mapita?.getSource('data-buhler-ov_users').setData(json1);
          })

  }

  async componentDidMount() {
    if (this.props.auth0) {
      const { getAccessTokenSilently } = this.props.auth0;
      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        });
        this.getMaps(token);
        this.setState({ token });
        
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    }

    const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {
			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

      map.addSource('data-buhler-ov_users', {
				'type': 'geojson',
				'data': plot_ov_users,
				'generateId': true
			});

      map.addLayer({
				'id': 'data-buhler-ov_users',
				'type': 'fill',
				'source': 'data-buhler-ov_users',
				'layout': {
				},
				'paint': {
					'fill-color': [
            'step',
            ['get', 'people'], 
            '#f48c06',
            3, '#dc2f02',
            5, '#d00000',
            8, '#9d0208',
            10, '#6a040f',
            15, '#370617'
          ],
					'fill-opacity': 0.5
				}
			});

    map.addLayer({
      'id': 'outline-data-buhler-ov_users',
      'type': 'line',
      'source': 'data-buhler-ov_users',
      'layout': {},
      'paint': {
          'line-color': [
            'step',
            ['get', 'people'], 
            '#f48c06',
            3, '#dc2f02',
            5, '#d00000',
            8, '#9d0208',
            10, '#6a040f',
            15, '#370617'
          ],
          'line-width': 1
      }
  });

  map.on('click', 'data-buhler-ov_users', (e) => {
    map.getCanvas().style.cursor = 'pointer';
    let elem = e.features[0].properties;
    let html = '<div><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Municipality:</span> ' + elem.Gemeindename + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">Postal Code:</span> ' + elem.PLZ + '</p><p style="margin-block-end: 0em;"><span style="font-weight: bold;">People with MobilityAbo:</span> ' + elem.people + '</p></div>'
    new mapboxgl.Popup()
						.setLngLat(e.lngLat)
						.setHTML(html).addTo(map);

		});


		});

    const { getAccessTokenSilently } = this.props.auth0;
      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        });
        this.getMaps(token);        
      } catch (error) {
        console.log('wrong maps')
      }

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
			center: map.getCenter()
		});
  }

  logoutWithRedirect = () => {
    const { logout } = this.props.auth0;
    logout({ returnTo: window.location.origin });
  };

  render() {
    setBodyColor({color: "#edeae6"});
    const { user } = this.props.auth0 || {};

    return (
      <>
        <NavBar_MyMo />
        <div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
      </>
    );
  }
}

export default withAuth0(MapOV);
