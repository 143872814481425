import React, { useState } from "react";
import './css/App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Login from './views/Login';
import Home from './views/Home';
import AccessDenied from "./views/AcessDenied";
import Main from "./views/Main";
import MapOV from "./views/MapOV";
import MapParking from "./views/MapParking";

import 'bootstrap/dist/css/bootstrap.min.css';
import { ProtectedRoute } from './utils/ProtectedRoute';
import MapAdquisition from "./views/MapAdquisition";
import ExcelExport from './views/Exports';

function App() {

  return (
    <div className="App">

      <Router history={createBrowserHistory}>
        <Routes>
        <Route path="/" element={<Home />} />
        <Route
            path="/main"
            element={<ProtectedRoute component={Main} />}
          />
          <Route
            path="/map-ov"
            element={<ProtectedRoute component={MapOV} />}
          />
           <Route
            path="/map-parking"
            element={<ProtectedRoute component={MapParking} />}
          />
          <Route
            path="/map-adquisition/*"
            element={<ProtectedRoute component={MapAdquisition} />}
          />
          <Route
            path="/excel-exports"
            element={<ProtectedRoute component={ExcelExport} />}
          />
        <Route path="/access-denied" element={<AccessDenied />} />
        <Route path="/login" element={<Login />} />
          </Routes>
      </Router>

    </div>

  );
}
export default App;

