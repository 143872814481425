import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useAuth0 } from "@auth0/auth0-react";

// Higher-Order Component (HOC) to pass auth0 props
const withAuth0 = (WrappedComponent) => {
  return function Auth0Component(props) {
    const { user, logout, getAccessTokenSilently } = useAuth0();
    return (
      <WrappedComponent
        {...props}
        auth0={{ user, logout, getAccessTokenSilently }}
      />
    );
  };
};

const AccessDenied = (props) => {
  const logoutWithRedirect = () => {
    const { logout } = props.auth0;
    logout({ returnTo: window.location.origin });
  };

  return (
    <div className="home-div">
      <div className="home-div-inner">
        <Container>
          <Row className="justify-content-md-center">
            <Col md="auto">
              <Image className='home-img' src={require("./../assets/img/banner-access-denied.png")} fluid />
            </Col>
            <Button variant="secondary" onClick={logoutWithRedirect} style={{margin: '5%', width:'90%'}}>Logout</Button>
          </Row>
        </Container>
      </div>
    </div>
  );
};

// Wrap AccessDenied component with the HOC to pass auth0 as props
export default withAuth0(AccessDenied);
