import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import { useAuth0 } from "@auth0/auth0-react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import { Link } from "react-router-dom";

const withAuth0 = (WrappedComponent) => {
  return function Auth0Component(props) {
    const { user, logout, getAccessTokenSilently } = useAuth0();
    return (
      <WrappedComponent
        {...props}
        auth0={{ user, logout, getAccessTokenSilently }}
      />
    );
  };
};

class NavBar_MyMo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  logoutWithRedirect = () => {
    const { logout } = this.props.auth0;
    logout({ returnTo: window.location.origin });
  };

  render() {
    const { user } = this.props.auth0 || {};

    return (
      <>
        <Navbar className="bg-body-tertiary">
          <Container>
            <Navbar.Brand>
              <img
                alt=""
                src={require("./../../assets/img/logo_my_mo.png")}
                width="30"
                height="30"
                className="d-inline-block align-top"
              />{' '}
              my·mo
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-start">
            <Link to="/main" style={{ textDecoration: 'none' }}>
            <Nav.Link className='btn_padding' disabled>Dashboard</Nav.Link>
            </Link>
            <Link to="/excel-exports" style={{ textDecoration: 'none' }}>
            <Nav.Link className='btn_padding' disabled>Excel Exports</Nav.Link>
            </Link>
            <Link to="/map-ov" style={{ textDecoration: 'none' }}>
            <Nav.Link className='btn_padding' disabled>Map ÖV</Nav.Link>
            </Link>
            <Link to="/map-parking" style={{ textDecoration: 'none' }}>
            <Nav.Link className='btn_padding' disabled>Map Parking</Nav.Link>
            </Link>
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                Welcome back, {user?.name}
              </Navbar.Text>
              <Navbar.Text><Button variant="info" className='btn_padding' onClick={this.logoutWithRedirect}>Logout</Button></Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default withAuth0(NavBar_MyMo);
