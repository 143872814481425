import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import setBodyColor from '../setBodyColor'
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '../css/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useParams } from "react-router-dom";
import NavBar_MyMo from "./components/NavBar";

const withAuth0 = (WrappedComponent) => {
  return function Auth0Component(props) {
    const { user, logout, getAccessTokenSilently } = useAuth0();
    return (
      <WrappedComponent
        {...props}
        auth0={{ user, logout, getAccessTokenSilently }}
      />
    );
  };
};

let plot_parking_users = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

class MapAdquisition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      lng: 8.251313,
			lat: 46.924143,
			zoom: 7,
			mapita: 9,
			tyle: 'mapbox://styles/mapbox/streets-v12',
      plot_parking_users: {},
      plot_ov_users: {}
    };
  }

  getMaps(token) {
    console.log(this.props);
    let value = this.props.params['*'].split(',');
    let options = [];
    for(let i=0; i<value.length; i++){
      options.push(value[i]);
    }

    fetch('https://api.enterprise-mobility.ch/get_quality_maps', {
			method: 'POST',
      body: JSON.stringify({options: options}),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
			},
		}).then((res) => res.json())
			.then((json) => {
        plot_parking_users = json;
				this.state.mapita.getSource('data-buhler-adquisition').setData(json);
			})
  }

  async componentDidMount() {
    if (this.props.auth0) {
      const { getAccessTokenSilently } = this.props.auth0;
      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        });
        this.getMaps(token);
        this.setState({ token });
        
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    }

    const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {
			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

      map.addSource('data-buhler-adquisition', {
				'type': 'geojson',
				'data': plot_parking_users,
				'generateId': true
			});

      map.addLayer({
				'id': 'data-buhler-adquisition',
				'type': 'fill',
				'source': 'data-buhler-adquisition',
				'layout': {
				},
				'paint': {
					'fill-color': ['get', 'color_combined'],
					'fill-opacity': 0.5
				}
			});

      map.addLayer({
        'id': 'outline-data-buhler-adquisition',
        'type': 'line',
        'source': 'data-buhler-adquisition',
        'layout': {},
        'paint': {
            'line-color': ['get', 'color_combined'],
            'line-width': 1
        }
    });

    map.addLayer({
      'id': 'labels-data-buhler-adquisition',
      'type': 'symbol',
      'source': 'data-buhler-adquisition',
      "minzoom": 10,
      'layout': {
        'text-field': ['get', 'trips'],
        'text-font': [
          'Open Sans Bold',
          'Arial Unicode MS Bold'
        ],
        'text-size': 15,
        'text-transform': 'uppercase',
        'text-letter-spacing': 0.05,
        'text-offset': [0, 1],
        'visibility': 'visible'
      }
    });

		});

    const { getAccessTokenSilently } = this.props.auth0;
      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        });
        this.getMaps(token);        
      } catch (error) {
        console.log('wrong maps')
      }

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
			center: map.getCenter()
		});
  }

  logoutWithRedirect = () => {
    const { logout } = this.props.auth0;
    logout({ returnTo: window.location.origin });
  };

  render() {
    setBodyColor({color: "#edeae6"});
    const { user } = this.props.auth0 || {};

    return (
      <>
        <NavBar_MyMo />
        <div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
      </>
    );
  }
}

// The functional component for useParams that passes the param to MapAdquisition
const MapAdquisitionWrapper = (props) => {
  console.log(useParams())
  return <MapAdquisition {...props} params={useParams()} />;
};

// Wrap MapAdquisitionWrapper with Auth0 HOC before exporting
export default withAuth0(MapAdquisitionWrapper);